.layout-topbar {
    position: fixed;
    height: 5rem;
    z-index: 997;
    left: 0;
    top: 0;
    width: 100%;

    transition: left 0.2s;
    display: flex;
    align-items: center;

    background: #ffffff;
    box-shadow: 0px 4px 43px rgba(85, 186, 248, 0.04);
}

.layout-topbar .layout-topbar-logo img {
    height: 2.5rem;
}

.layout-topbar .layout-topbar-menu .layout-topbar-button {
    margin-left: 1rem;
}

.layout-topbar .layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: transparent;
    border: none;
}

.layout-topbar .layout-topbar-button:hover {
    color: var(--text-color) !important;
    background-color: var(--surface-hover) !important;
}

.layout-topbar .layout-topbar-button i {
    font-size: 1.5rem;
}

.layout-right-wrap {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
}

.layout-topbar .layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

/* .layout-topbar .layout-topbar-menu-button {
    display: none;
} */

.layout-topbar .layout-topbar-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: none;
}

.layout-topbar .layout-topbar-button span {
    font-size: 1rem;
    display: none;
}

.layout-topbar .layout-topbar-menu.layout-topbar-menu-mobile-active {
    display: block;
}

.layout-topbar .layout-topbar-menu {
    margin-left: 0;
    position: absolute;
    flex-direction: column;
    background-color: var(--surface-overlay);
    box-shadow: 0 3px 5px rgb(0 0 0 / 2%), 0 0 2px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 8%);
    border-radius: 12px;
    padding: 1rem;
    right: 2rem;
    top: 5rem;
    min-width: 15rem;
    display: none;
    animation: scalein 0.15s linear;
}

.layout-topbar .layout-topbar-menu .layout-topbar-button {
    margin-left: 0;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-start;
    border-radius: 12px;
    padding: 1rem;
}

.layout-topbar .layout-topbar-menu .layout-topbar-button i {
    font-size: 1rem;
    margin-right: 0.5rem;
}

.layout-topbar .layout-topbar-menu .layout-topbar-button span {
    font-weight: medium;
    display: block;
}

.p-overlay-badge {
    position: relative;
}

.p-overlay-badge .p-badge {
    background-color: #eee !important;
    font-size: 13px !important;
}

.user-heading {
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 1.5rem;

    color: #111522;
}

.default-user-image img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #a2a2a2;
}

.app-header-logo img {
    filter: brightness(0) saturate(100%) invert(31%) sepia(86%) saturate(682%) hue-rotate(223deg) brightness(111%) contrast(128%);
}

@media (max-width: 991px) {
    .layout-topbar {
        justify-content: space-between;
        padding: 0 0.5rem;
    }

    .layout-topbar .layout-topbar-logo {
        width: auto;
        /* order: 2; */
    }

    .layout-right-wrap {
        display: inline-flex;
        margin-left: 0;
        /* order: 3; */
    }
}
