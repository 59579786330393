/** IMPORTS CSS */
@import './variables.css';
@import './theme.css';

/** CUSTOM CSS FOR WHOLE APP */

body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--primary-background);
}

.wrapper {
    max-width: 1366px;
    padding: 0 16px;
    width: 100%;
    margin: 0 auto;
}

.bg-secondary {
    background-color: var(--secondary);
}

.layout-wrapper {
    min-height: 100vh;
}

.layout-main {
    flex: 1 1 auto;
}
div#pr_id_13 .p-dialog-content .employer-onboarding-logo-container,
div#pr_id_13 .p-dialog-content .employer-onboarding-content-container {
    min-height: 480px;
}

.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: 12px;
}

.p-skeleton {
    background-color: #f2f2f2;
}

.primary-btn {
    font-family: var(--font-family);
    font-style: var(--font-style);
    font-weight: 600;
    font-size: 1.43rem;
    padding: 0.5rem 2.5rem;
    color: var(--primary-text);
    background: var(--primary);
    border: 2px solid var(--secondary);
    border-radius: 5px;
    box-sizing: border-box;
}

.primary-btn:hover {
    background: var(--secondary) !important;
    color: var(--secondary-text) !important;
    border: 2px solid var(--secondary) !important;
}

.primary-btn:focus {
    box-shadow: none;
}

.primary-btn-a {
    font-family: var(--font-family);
    font-style: var(--font-style);
    font-weight: 600;
    font-size: 1.43rem;
    color: var(--primary-text);
    background: var(--primary);
    border: 2px solid var(--secondary);
    border-radius: 5px;
    box-sizing: border-box;
    min-width: 100px;
    width: 100%;
    /* height: 40px; */
    text-align: center;
    display: inline-block;
}

.primary-btn-a:hover {
    background: var(--secondary) !important;
    color: #854dff !important;
    border: 2px solid var(--secondary) !important;
}

.primary-btn-a:focus {
    box-shadow: none;
}
.error {
    color: red;
}

.secondary-btn {
    font-family: var(--font-family);
    font-style: var(--font-style);
    font-weight: 600;
    font-size: 1.43rem;
    padding: 1rem 5rem;
    color: var(--primary-text);
    background: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 5px;
    box-sizing: border-box;
    min-width: 305px;
}

.secondary-btn:hover {
    border: 2px solid var(--primary) !important;
    background: var(--primary-text) !important;
    color: var(--primary) !important;
}

.secondary-btn:focus {
    box-shadow: none;
}

.secondary-btn-a {
    font-family: var(--font-family);
    font-style: var(--font-style);
    font-weight: 600;
    font-size: 1.43rem;
    padding: 1rem 5rem;
    color: var(--secondary-text);
    background: var(--secondary);
    border: 2px solid var(--primary);
    border-radius: 5px;
    box-sizing: border-box;
    min-width: 305px;
}

.secondary-btn-a:hover {
    color: var(--primary-text) !important;
    background: var(--primary) !important;
    border: 2px solid var(--primary) !important;
}

.secondary-btn-a:focus {
    box-shadow: none;
}
.primary-heading2 {
    height: calc(100vh - 111px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.primary-heading {
    font-family: var(--font-family-heading);
    font-style: var(--font-style);

    font-weight: 600;
    font-size: 4.9rem;

    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    margin-top: 10rem;

    color: #111522;
}

.secondary-heading {
    font-family: var(--font-family-heading);
    font-style: var(--font-style);

    font-weight: 700;
    font-size: 4rem;

    text-align: center;

    color: #111522;
}

.full-img img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.job-card:hover {
    box-shadow: 0px 0 12px 1px #854dffc0;
    transform: scale(1.01);
    transition: 600ms;
}
.job-card {
    background: #fff;
    box-shadow: 0px 12px 26px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    transition: 600ms;
}
.mymessage h4 {
    margin-bottom: 4px;
    margin-top: 7px;
    font-size: 18px;
}

.job-card-profile-image {
    width: 52px;
    height: 52px;

    border-radius: 38px;
}

.job-card-city-heading {
    font-family: var(--font-family-heading);
    font-style: var(--font-style);

    font-weight: 600;
    font-size: 1.375rem;

    color: #202124;
}

.job-card-title-heading {
    font-family: var(--font-family);
    font-style: var(--font-style);

    font-weight: 400;
    font-size: 1.287rem;
    /* identical to box height, or 118% */

    color: #696969;
}

.job-card-salary {
    background: rgba(57, 194, 30, 0.1);
    border-radius: 57px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;

    color: #39c21e;
    padding: 0.5rem 1rem;
}

.job-card-last-online {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;

    color: #40d0c9;
}

.job-card-response {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;

    color: #55a4e6;
}

.candidate-card {
    box-sizing: border-box;
    box-shadow: 0px 12px 26px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    border: 1px solid #ecedf2;
    border-radius: 8px;
}

.candidate-card:hover * {
    color: #fff !important;
    cursor: pointer;
}
.candidate-card .candidate-location:hover,
.candidate-card:hover .candidate-location {
    filter: brightness(0) saturate(100%) invert(86%) sepia(100%) saturate(2%) hue-rotate(294deg) brightness(114%) contrast(101%);
}

.candidate-card:hover {
    background: #854dff;
    box-shadow: 0px 12px 26px rgba(0, 0, 0, 0.14);
}

.recent-candidate-card-profile img {
    width: 137.12px;
    height: 137.12px;
    border-radius: 68.5586px;
    border: 1px solid #ddd;
}

.active-employer-salary {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    color: #1e1c1c;
}

.active-employer-follow {
    background: rgba(25, 103, 210, 0.15);
    border-radius: 103px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #1967d2;

    padding: 10px 20px;
}

.active-employer-benefit1 {
    background: rgba(64, 208, 201, 0.15);
    border-radius: 103px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 0.775rem;

    color: #40d0c9;
    padding: 10px 20px;
}

.active-employer-benefit2 {
    background: #efffec;
    border-radius: 103px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 0.775rem;

    color: #50c13a;
    padding: 10px 20px;
}

.review-section {
    background: #854dff;
}
.reviews-container {
    max-width: 1420px;
    padding: 0 16px;
    width: 100%;
    margin: 0 auto;
}
.job-card img {
    max-width: 100%;
}

.review-card {
    box-sizing: border-box;
    /* min-height: 299px; */

    background: #ffffff;
    border: 1px solid rgba(85, 186, 248, 0.17);
    border-radius: 8px;
}

.review {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #696969;
    min-height: 140px;
}

.p-carousel .review-section-indicator .p-carousel-indicator.p-highlight button {
    background-color: #9898980d;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
}
.p-carousel .review-section-indicator .p-carousel-indicator button {
    background-color: #f7fafc;

    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
}

.review-section-carousel .p-carousel .p-carousel-content .p-carousel-container .p-carousel-prev {
    color: white;
    background-color: #854dff;
    width: 43px;
    height: 43px;
}

.review-section-carousel .p-carousel .p-carousel-content .p-carousel-container .p-carousel-next {
    color: white;
    background-color: #854dff;
    width: 43px;
    height: 43px;
}

.layout-footer {
    background: #121212;
}

.footer-plateform-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;

    color: #ffffff;
}

.footer-section-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;

    color: #ffffff;
}

.foooter-text,
.foooter-text a {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;

    color: #ffffff;
}

.heading-container {
    height: calc(100vh - 200px);
}

.employer-onboarding-logo-container {
    box-sizing: border-box;

    background: #55baf8;
    border-radius: 5px 0px 0px 5px;
}

.employer-onboarding-content-container {
    box-sizing: border-box;

    background: #f7fafc;
    border-radius: 5px;
}

.employer-questions-heading {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    /* identical to box height, or 190% */

    color: #111522;
}

.available-questions-options-select {
    box-sizing: border-box;

    padding: 9px 16px;

    background: rgba(85, 186, 248, 0.04);
    border: 1.5px solid #55baf8 !important;
    border-radius: 46px;

    box-sizing: border-box;
}

.available-questions-options-select-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #55baf8;
}

.available-questions-options {
    box-sizing: border-box;

    /* Auto layout */
    padding: 9px 16px;
    border: 1px solid transparent;
    background: #ffffff;
    border-radius: 46px;
}

.available-questions-options-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #7b7b7b;
}

.line-horizontal {
    opacity: 0.5;
    border: 1px solid #bfc7d4;
}
.p-progressbar {
    background: #ffffff;
    border-radius: 25px;
}

.p-progressbar .p-progressbar-value {
    border: 20 none;
    margin: 0;
    background: #854dff;
    border-radius: 25px;
}

.progress-bar-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;

    color: #8d8d8d;
}

.p-dialog-header {
    display: none;
}
.previewdialog .p-dialog-header .p-dialog-header-icons,
.signup-view .p-dialog-header .p-dialog-header-icons {
    justify-content: flex-end;
}
.previewdialog .p-dialog-header,
.signup-view .p-dialog-header {
    display: block;
}
.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0;
}

.p-dialog .p-dialog-content:last-of-type {
    border-radius: 5px;
}

.user-selection-container-active {
    box-sizing: border-box;
    position: relative;

    background: #ffffff;
    min-width: 190px;

    padding: 0.5rem 2rem;
    /* Primary */

    border: 1px solid #62bae6;
    box-shadow: 0px 13px 27px rgba(85, 186, 248, 0.07);
    border-radius: 5px;
}

.user-selection-container-active-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;

    color: #62bae6;
}

.user-selection-container-in-active {
    position: relative;
    padding: 0.5rem 2rem;
    box-sizing: border-box;
    background: #e1e1e1;
    border: 1px solid #9a9a9a;
    border-radius: 5px;
    color: #8d8d8d;
    min-width: 190px;
}

.user-selection-container-in-active-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;

    color: #8d8d8d;
}
.onboarding-btn {
    background: #55baf8;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;
    /* identical to box height, or 112% */
    color: #ffffff;
    box-shadow: none;
    border: 2px solid #55baf8;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
}

.onboarding-btn:hover {
    background: #55baf8 !important;
    color: #ffffff !important;
    border: 2px solid #55baf8 !important;
}

.onboarding-btn:focus {
    box-shadow: none;
}

.onboarding-btn-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;
    /* identical to box height, or 112% */
    box-shadow: none;
    color: #55baf8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;

    background: #ffffff;
    border: 1px solid #55baf8;
    border-radius: 5px;
}

.onboarding-btn-2:hover {
    background: #ffffff !important;
    border: 1px solid #55baf8 !important;
    color: #55baf8 !important;
}

.onboarding-btn-2:focus {
    box-shadow: none;
}

.onboarding-btn-3 {
    background: #55baf8;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;
    color: #ffffff;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tick-image {
    position: absolute;
    top: -20px;
    right: -2px;
}

.add-button {
    box-sizing: border-box;

    padding: 6px 16px;

    background: #55baf8;
    border-radius: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    min-height: 28px;
    max-width: 80px;
}

.add-button-2 {
    box-sizing: border-box;

    padding: 6px 16px;

    background: #55baf8;
    border-radius: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 199px;
    min-height: 28px;
    max-width: 80px;
}

.add-button-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
}

.add-button-container {
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.small-add-button {
    min-width: 65px;
}

.add-button-container-input {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #7b7b7b;
}

.add-button-container-input:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #fff;
}

.add-button-2 {
    box-sizing: border-box;

    padding: 6px 16px;

    background: #55baf8;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 64px;
    min-height: 30px;
}

.add-button-text-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.15rem;
    color: #ffffff;
}

.social-media-dropdown {
    box-sizing: border-box;
    border: none;

    background: rgba(85, 186, 248, 0.09);
    border-radius: 5px;

    font-family: 'Raleway';
    border: 1px solid #e0e0e0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* identical to box height, or 114% */

    color: #55baf8 !important;
}

.social-active-link {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* identical to box height, or 133% */

    color: #55baf8;
}

.social-container {
    background: #ffffff;
    border-radius: 5px;
}
.p-dropdown-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #55baf8;
}
div.interviewtracker {
    background-color: transparent;
    border: 1px solid #f2f2f2;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #55baf8;
}

.benefit-image-select img {
    filter: invert(63%) sepia(100%) saturate(1082%) hue-rotate(174deg) brightness(99%) contrast(96%);
}

.benefit-image img {
    filter: invert(46%) sepia(6%) saturate(190%) hue-rotate(161deg) brightness(99%) contrast(89%);
}

.benefit-icons img {
    filter: invert(70%) sepia(99%) saturate(2164%) hue-rotate(175deg) brightness(100%) contrast(95%) !important;
}

.employee-company-logo img {
    box-sizing: border-box;
    width: 114.73px;
    height: 114.73px;
    border-radius: 50%;
    border: 1px solid #55baf8;
}

.upload-logo-button {
    box-sizing: border-box;

    background: #55baf8;
    border-radius: 46px;
}

.p-fileupload-choose {
    box-sizing: border-box;

    background: #55baf8;
    border-radius: 46px;
    border: 1px solid #55baf8;
}

.p-fileupload-choose:hover {
    box-sizing: border-box;
    background: #55baf8 !important;
    border-radius: 46px;
    border: 1px solid #55baf8 !important;
}

.p-fileupload-choose:focus {
    box-shadow: none;
}

.employe-textarea {
    background: #ffffff;
    border-radius: 5px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #7b7b7b;
}

.employee-office-images {
    width: 136.51px;
    height: 123.98px;
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
}

.employee-office-images-add-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 136.51px;
    height: 123.98px;
    background: #d6d6d6;

    border-radius: 5px;
}

.employee-signup-input-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #111522;
}

.employee-signup-input {
    background: #ffffff !important;
    border-radius: 5px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #444;
    background: #f7fafc;
    border: 1px solid #44444421 !important;
}

.employee-signup-input-2 {
    border-radius: 5px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #9f9f9f;
    background: #f7fafc;
    border: 1px solid #44444421 !important;
}
.dialog-box {
    width: 80vw;
    /* height: 41vw; */
}

.dialog-box-2 {
    width: 40vw;
    height: 22vw;
}

.confirmation-dialog-box {
    background: #ffffff;
    border-radius: 12px;
    width: 400px;
    height: 287px;
    padding: 32px 24px;
    gap: 16px;
}
.confirmation-dialog-box-heading {
    font-family: 'Helvetica Now Text';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;

    letter-spacing: 0.03em;

    color: #000000;
}

.confirmation-dialog-box-content-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #8d8d8d;
}

.dialog-box-4 {
    width: 25vw;
    height: 10vw;
}
.dental-tools-edit {
    width: 50vw;
    height: 50vw;
}

.login-welcome-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    /* identical to box height, or 119% */

    color: #55baf8;
}

.forgot-password-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* identical to box height, or 133% */

    text-decoration-line: underline;

    color: #55baf8;
}
.batch-logo img {
    width: 90%;
    margin: 0px 10px;
}
.batch-back-btn {
    width: 100%;
    display: inline-block;
    text-align: right;
}

.input-code {
    min-width: 47px;
    min-height: 40px;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    border: none;
    width: 80% !important;

    background: #ffffff;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #7b7b7b;
}

.input-code:focus-visible {
    border-radius: 5px;
    outline: none;
    border: none;
}

.preview-screen-wrapper {
    margin: 50px 100px;
}

.heading-container-employee {
    box-sizing: border-box;
    background: #55baf8;
    border: 3px solid rgba(98, 183, 230, 0.5);
    border-radius: 12px 12px 0px 0px;
    min-height: 4.0625rem;
    min-width: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading-container-employee-2 {
    box-sizing: border-box;
    background: #55baf8;
    border: 3px solid rgba(98, 183, 230, 0.5);
    border-radius: 12px 12px 0px 0px;
    min-height: 4.0625rem;
    min-width: 18rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading-container-employee-3 {
    box-sizing: border-box;
    min-height: 4.0625rem;
    min-width: 18rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #55baf8;
    border-radius: 12px 12px 0px 0px;
    text-align: center;
}

.heading-container-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.875rem;

    color: #ffffff;
}

.heading-container-text-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.875rem;
    width: 100%;
    text-align: center;
    color: #ffffff;
}

.screen-benefit-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* identical to box height, or 32px */

    color: #181c20;
}

.horizontal-line {
    opacity: 0.5;
    border: 2px solid rgba(85, 186, 248, 0.37);
    /* min-width: 18rem; */
}

.info-txt {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1.75rem;

    color: #181c20;
}

.mission-statment-txt {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;

    color: #5d6063;
}

.office-info-heading {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1.15rem;

    color: #5d6063;
}

.office-info-heading-data {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #31475f;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.employee-love-container {
    background: #fff;
    border-radius: 4px;
    width: 18.75rem;
    height: 3.4rem;
    border: 1px solid #eef4ff;
}

.employee-love-container-2 {
    background: #fff;
    border-radius: 4px;
}

.employee-love-container-txt {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    color: #747779;
}

.social-media-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
}
.social-media-icon a span img {
    max-width: 38px;
}
.logo-employee img {
    min-width: 90px;
    min-height: 90px;
    max-width: 90px;
    max-height: 90px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.back-icon-preview-screen img {
    width: 42px;
    height: 42px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 10px;
}
.dashboard-blue-card {
    background: #55baf8;
    border-radius: 10.7872px;
}

.dashboard-blue-card-text-1 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;

    text-transform: capitalize;

    color: #ffffff;
}

.dashboard-blue-card-text-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.43rem;
    text-transform: capitalize;

    color: #ffffff;
}

.dashboard-blue-card-text-3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.75rem;

    text-transform: capitalize;

    color: #ffffff;
}

.dashboard-blue-card-text-4 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;

    text-transform: capitalize;

    color: #ffffff;
}

.dashboard-blue-card-btn {
    background: #ffffff;
    border: 0.5px solid #ffffff;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    width: 111px !important;
    height: 25px !important;

    text-transform: capitalize;
    justify-content: center;

    color: #55baf8;
}

.interviewform .field {
    margin: 15px 0;
}
.interviewform .p-dropdown,
.interviewform input,
.interviewform #message {
    background: rgba(85, 186, 248, 0.06);
    border: 1px solid #55baf8;
    border-radius: 8px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #55baf8;
}
.dashboard-blue-card-btn:hover {
    background: #ffffff !important;
    color: #55baf8 !important;
    border: 0.5px solid #ffffff !important;
    border-radius: 5px;
}
.dashboard-blue-card-btn:focus {
    box-shadow: none;
}

.arrow-blue-card img {
    transform: rotate(270deg);
}

.date-dropdown-blue-card {
    background: transparent;
    border: none;
}
.date-dropdown-blue-card:focus {
    box-shadow: none;
    border-radius: none !important;
}

.date-dropdown-blue-card .p-dropdown-label.p-placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #fff !important;
    padding-left: 0;
    padding-right: 3px;
}

.date-dropdown-blue-card .p-dropdown-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #fff !important;
}

.date-dropdown-blue-card .p-dropdown-trigger {
    width: 16px;
}

.date-dropdown-blue-card .p-dropdown-trigger-icon {
    filter: brightness(0) invert(1);
}

/** black card */

.dashboard-black-card {
    background: #111522;
    border-radius: 10.7872px;
}

.dashboard-black-card-text-1 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;

    text-transform: capitalize;
    color: #ffffff;
}

.dashboard-black-card-text-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.43rem;
    text-transform: capitalize;
    color: #838383;
}

.dashboard-black-card-text-3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.75rem;

    text-transform: capitalize;

    color: #ffffff;
}

.dashboard-black-card-text-4 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;

    text-transform: capitalize;

    color: #ffffff;
}

.dashboard-black-card-btn {
    background: #ffffff;
    border: 0.5px solid #ffffff;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    width: 111px !important;
    height: 25px !important;

    text-transform: capitalize;

    color: #111522;
    justify-content: center;
}

.dashboard-black-card-btn:hover {
    background: #ffffff !important;
    color: #111522 !important;
    border: 0.5px solid #ffffff !important;
    border-radius: 5px;
}
.dashboard-black-card-btn:focus {
    box-shadow: none;
}

.arrow-black-card img {
    transform: rotate(1800deg);
}

.date-dropdown-black-card {
    background: #111522 !important;
    border: none;
}
.date-dropdown-black-card:focus {
    box-shadow: none;
    border-radius: none !important;
}

.date-dropdown-black-card .p-dropdown-label.p-placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #55baf8 !important;
    padding-left: 0;
    padding-right: 3px;
}

.date-dropdown-black-card .p-dropdown-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #55baf8 !important;
}

.date-dropdown-black-card .p-dropdown-trigger {
    width: 16px;
}

.date-dropdown-black-card .p-dropdown-trigger-icon {
    filter: invert(59%) sepia(66%) saturate(538%) hue-rotate(173deg) brightness(102%) contrast(94%);
}

/** white card */

.dashboard-white-card {
    background: #ffffff;
    border: 1.01962px solid rgba(85, 186, 248, 0.32);
    border-radius: 10.7872px;
}

.dashboard-white-card-text-1 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;

    text-transform: capitalize;
    color: #111522;
}

.dashboard-white-card-text-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.43rem;
    text-transform: capitalize;
    color: #111522;
}

.dashboard-white-card-text-3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.75rem;

    text-transform: capitalize;

    color: #111522;
}

.dashboard-white-card-btn {
    background: #000000;
    border: 0.5px solid #000000;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    width: 8rem !important;
    height: 1.61rem !important;
    text-transform: capitalize;
    justify-content: center;
    color: #ffffff;
}

.dashboard-white-card-btn:hover {
    background: #000000 !important;
    color: #fff !important;
    border: 0.5px solid #000000 !important;
    border-radius: 5px;
}
.dashboard-white-card-btn:focus {
    box-shadow: none;
}

.date-dropdown-white-card {
    background: #ffff !important;
    border: none;
}
.date-dropdown-white-card:focus {
    box-shadow: none;
    border-radius: none !important;
}

.date-dropdown-white-card .p-dropdown-label.p-placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #111522 !important;
    padding-left: 0;
    padding-right: 3px;
}

.date-dropdown-white-card .p-dropdown-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #111522 !important;
}

.date-dropdown-white-card .p-dropdown-trigger {
    width: 16px;
}

.date-dropdown-white-card .p-dropdown-trigger-icon {
    filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7431%) hue-rotate(234deg) brightness(110%) contrast(114%);
}

.dashboard-heading {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;

    color: #111522;
}

.mat-icon {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    background: #e0e0e0;
    border: 1px solid #e0e0e0;
    color: #858585;
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.dashboard-card-container {
    background: #f7fafc;
    border: 3px solid #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/** popular candidate on dashboard*/
.popular-candidate-card {
    box-sizing: border-box;

    background: #ffffff;
    border-radius: 10px;

    padding: 10px;
}

.popular-candidate-card-profile-image img {
    width: 3.188rem;
    height: 3.188rem;

    border-radius: 2.813rem;
}

.popular-candidate-view-profile {
    box-sizing: border-box;

    background: #f7fafc;
    border-radius: 5px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;
    /* identical to box height, or 162% */

    color: #55baf8;

    border: none;
    padding: 0.5rem 6px;
}

.popular-candidate-view-profile:hover {
    background: #f7fafc !important;
    color: #55baf8 !important;
    border-radius: 5px;
    border: none;
}
.popular-candidate-view-profile:focus {
    box-shadow: none;
}

.popular-candidate-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.15rem;

    color: #202124;
}

.popular-candidate-location-name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    max-width: 90px;

    color: #696969;
}

.back-image {
    display: none;
}

.dashboard-blue-icon img {
    width: 43px;
    height: 43px;
}

/* Search Candidates */

.search-candidates-heading {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 2.15rem;

    color: #000000;
}

.search-candidates-ui {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 20px -15px;
}

.candidate-search-profile-image img {
    border-radius: 85px;
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
    border: 1px solid #d2d2d2;
}

.candidate-search-name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.43rem;

    color: #55baf8;
}

.candidate-search-details {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #000000;
}

.candidate-search-container {
    border-left: 1px solid #8d8d8d;
    height: 95px;
}

.candidate-search-container-2 {
    border-left: 1px solid #8d8d8d;
    height: 95px;
}

.candidate-search-details-heading {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;

    color: #000000;
}

.candidate-search-hiring-status {
    font-family: 'Raleway';
    font-style: normal;

    font-weight: 500;
    font-size: 10px;

    color: #000000;
}

.candidate-benefit-container-1 {
    background: rgba(136, 80, 255, 0.08);
    border: 0.5px solid #8850ff;
    border-radius: 32px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #8850ff;
}
.main-ben {
    font-size: 13px !important;
    padding: 5px 8px !important;
    border-radius: 32px;
    border: 0.5px solid !important;
}

.candidate-benefit-container-2 {
    background: rgba(85, 186, 248, 0.07);
    border: 0.5px solid #55a4e6;
    border-radius: 32px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;

    color: #55a4e6;
}

.candidate-benefit-container-3 {
    background: rgba(57, 194, 30, 0.04);
    border: 0.5px solid #39c21e;
    border-radius: 32px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;

    color: #39c21e;
}

.candidate-decline-btn {
    background: #e00000;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;
    color: #ffffff;
    box-shadow: none;
    border: 2px solid #e00000;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
}

.candidate-decline-btn:hover {
    background: #e00000 !important;
    color: #ffffff !important;
    border: 2px solid #e00000 !important;
}

.candidate-decline-btn:focus {
    box-shadow: none;
}

.candidate-search-filter-container {
    background: #ffffff;
    border-radius: 10px;
}

.candidate-search-filter-heading {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 1.875rem;

    letter-spacing: 0.03em;

    color: #000000;
}

.candidate-search-filter-dropdown {
    box-sizing: border-box;
    background: rgba(85, 186, 248, 0.06);
    border: 1px solid #55baf8;
    border-radius: 8px;
}

.candidate-search-filter-dropdown .p-dropdown-trigger-icon {
    filter: invert(59%) sepia(66%) saturate(538%) hue-rotate(173deg) brightness(102%) contrast(94%);
}

.candidate-filter-calender {
    background: #ffffff;
    border-color: #55baf8;
    box-sizing: border-box;
    background: rgba(85, 186, 248, 0.06);
    border: 1px solid #55baf8;
    border-radius: 6px;
    width: 144px;
    color: #55baf8;
}

.candidate-filter-calender .p-inputtext {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #55baf8;
    border-color: #fff;
}

.candidate-filter-calender .p-inputtext:enabled:focus {
    border-color: #fff;
    box-shadow: none;
    background: #ffffff;
}
.candidate-filter-calender .inputtext:enabled:hover {
    border-color: #fff;
    box-shadow: none;
    background: #ffffff;
}

.candidate-filter-calender button {
    background: #fff;
    border-color: #fff;
    color: #fff;
}
.candidate-filter-calender .p-button:hover {
    background: #fff;
    border-color: #fff;
    color: #fff;
}

.candidate-filter-calender .pi-calendar {
    content: url('../../assets/images/calender.svg') !important;
    filter: brightness(0) saturate(100%) invert(80%) sepia(39%) saturate(5947%) hue-rotate(177deg) brightness(104%) contrast(94%);
}

.candidate-filter-calender .p-inputtext::placeholder {
    color: #55baf8;
}

.hourly-container {
    background: transparent;
    border: none;
    border-radius: 0;
}

.hourly-container .p-dropdown-label.p-placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    text-transform: capitalize;
    display: flex;
    align-self: center;

    color: #000000;
}

.hourly-container .p-dropdown-trigger-icon {
    filter: brightness(0) saturate(100%) invert(0%) sepia(9%) saturate(7%) hue-rotate(240deg) brightness(101%) contrast(101%);
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #55baf8;
}

.p-slider {
    background: #e3f4ff;
    border-radius: 39px;
}

.p-slider .p-slider-handle {
    height: 1.143rem;
    width: 0.6rem;

    background: #55baf8;
    border-radius: 2px;
}

.p-slider-horizontal {
    height: 10px;
    margin-top: 20px;
}

.slider-label {
    position: absolute;
    transform: translateX(-50%);

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #8d8d8d;
}

.slider-label.left {
    top: 40px;
}

.slider-label.right {
    top: 40px;
}
/* ----------------------------------------------/ */
.candidate-container {
    overflow-y: scroll;
    overflow-x: hidden;
}
.candidate-container::-webkit-scrollbar {
    width: 7px;
    height: 5px;
}
/* Track */
.candidate-container::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 5px;
}
/* Handle */
.candidate-container::-webkit-scrollbar-thumb {
    background: #55baf8;
    border-radius: 10px;
}
/* Handle on hover */
.candidate-container::-webkit-scrollbar-thumb:hover {
    height: 10px;
}
/* -------------------------------------------------- */

/* Search Candidates View Profile*/

.candidate-view-profile-image img {
    border-radius: 50%;
    max-width: 100px;
    min-width: 100px;
    max-height: 100px;
    min-height: 100px;
    border: 1px solid #d5d5d5;
}

.candidate-message-profile-image img {
    max-width: 60px;
    max-height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.candidate-view-profile-name {
    font-family: 'Raleway';
    font-style: normal;

    font-weight: 600;
    font-size: 22px;

    color: #181c20;
}

.candidate-view-profile-industry {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.43rem;
    /* identical to box height, or 118% */

    color: #747779;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.candidate-view-profile-employeer-name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.43rem;

    color: #55baf8;
}

.candidate-view-profile-employeer-job {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.43rem;

    color: #000000;
}

.candidate-view-profile-job-dates {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #000000;
}

.candidate-view-profile-heading-container {
    box-sizing: border-box;
    background: #55baf8;
    border: 3px solid rgba(98, 183, 230, 0.5);
    border-radius: 12px 12px 0px 0px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.candidate-view-profile-heading-container-2 {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #55baf8;
    border-radius: 12px 12px 0px 0px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.candidate-view-profile-heading--container-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;

    color: #ffffff;
}

.candidate-view-profile-heading--container-text-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;

    color: #55baf8;
}

.candidate-view-profile-computer-container {
    background: #f7fafc;
    border-radius: 11.0411px;
}
.candidate-view-profile-computer-container-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 0.92rem;
}
.candidate-view-profile-dental-container {
    background: #ffffff;
    border: 1px solid #55baf8;
    border-radius: 0 0 12px 12px;
}

.skill-container {
    background: #ffffff;
    border: 3px solid #eeeeee;
    border-radius: 2px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #181c20;
    padding: 8px 16px;
    border-radius: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.weekday-container {
    padding: 6px 18px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;
    background: #ffffff;
    border-radius: 56px;
    color: #000000;
}

.weekday-container-active {
    padding: 6px 18px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;
    background: rgba(85, 186, 248, 0.2);
    border-radius: 56px;
    color: #55baf8;
}
.candidate-view-profile-right-container {
    background: #ffffff;
    border-radius: 10px;
}

/** Message Employee*/

.employee-message-left-container {
    background: #fff;
    border-radius: 10px 0px 0px 10px;
    height: 750px;
}

.employee-message-left-single-user {
    background: #fff;
    padding: 10px 8px !important;
    min-width: 120px;
}

.employee-message-left-single-user-selected {
    background: #f7fafc;
    padding: 10px 5px;
    min-width: 120px;
}

.employee-message-left-single-user:hover {
    background: #f7fafc;
    cursor: pointer;
}

/* Scroller*/

.employee-message-left-container {
    overflow-y: scroll;
}
.employee-message-left-container::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}
/* Track */
.employee-message-left-container::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 5px;
}
/* Handle */
.employee-message-left-container::-webkit-scrollbar-thumb {
    background: #55baf8;
    border-radius: 10px;
}
/* Handle on hover */
.employee-message-left-container::-webkit-scrollbar-thumb:hover {
    height: 10px;
}

.employee-message-right-container {
    background: #ffffff;
    border-radius: 0px 10px 10px 0px;
    height: 750px;
}
.employee-message-search-container {
    box-sizing: border-box;
    /* width: 80%; */
    background: rgba(85, 186, 248, 0.06);
    border: 1px solid #55baf8;
    border-radius: 8px;
}
.search-input {
    border: 0 !important;
    border-radius: 0;
    background: transparent;
    color: #55baf8;
}

.search-input .p-inputtext {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 0.75rem 0.75rem;
}

.search-input .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0 #bfdbfe;
}

.employee-message-candidate-name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;

    color: #1e1e1e;
}

.employee-message-candidate-last-msg {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;

    color: #1e1e1e;
}

.employee-message-candidate-last-online {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #1e1e1e;
}

.employee-message-candidate-offline {
    height: 8px;
    padding: 5px;
    width: 8px;
    border-radius: 50%;

    background: #8d8d8d;
}

.employee-message-candidate-online {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #00d62b;
    padding: 5px;
}

.employee-message-candidate-name-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;

    color: #1e1e1e;
}

.employee-message-candidate-last-time {
    background: #f7fafc;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;

    color: #ababab;

    border-radius: 14px;
}

.employee-message-candidate-message-reciver-container {
    background: #f7fafc;
    border-radius: 10px;
}

.employee-message-candidate-message-reciver {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;

    color: #1e1e1e;
}

.employee-message-candidate-message-sender-container {
    background: #55baf8;
    border-radius: 10px;
}

.employee-message-candidate-message-sender {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;

    color: #fff;
}

.employee-message-candidate-message-container {
    background: #f7fafc;
    border-radius: 4px;
}

.employee-message-candidate-time {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #b9b9b9;
}

/** Employee Batch Match*/

.employee-batch-match-profile-name {
    font-family: 'Raleway';
    font-style: normal;

    font-weight: 600;
    font-size: 1.5rem;

    color: #181c20;
}

.employee-batch-match-profile-industry {
    font-family: 'Raleway';
    font-style: normal;

    font-weight: 400;
    font-size: 1.15rem;
    /* identical to box height, or 118% */

    color: #747779;
}

.employee-batch-match-info-heading {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;

    color: #5d6063;
}

.employee-batch-match-info-heading-data {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;

    color: #31475f;
}

.employee-batch-match-heading-container-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;

    color: #ffffff;
}

.employee-batch-match-heading-container-text-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;

    color: #55baf8;
}

.employee-batch-match-heading-container {
    background: #f7fafc;
    border-radius: 12px;
}

.employee-batch-match-active-skill-container {
    background: rgba(85, 186, 248, 0.1);

    border: 3px solid #55baf8;
    border-radius: 20px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.43rem;
    color: #55baf8;
    padding: 8px 16px;
}

.p-carousel .batch-match-indicator .p-carousel-indicator.p-highlight button {
    min-width: 60px;
    width: 100%;
    background: #62bae6;
    border-radius: 8px;
}
.p-carousel .batch-match-indicator .p-carousel-indicator button {
    min-width: 40px;
    width: 100%;
    border-radius: 8px;
}

.batch-match-operations {
    position: absolute;
    right: 0px;
    top: 55%;
    transform: translateY(-50%);
}

.profile-interviews-operations {
    position: absolute;
    right: 0px;
    top: 90%;
    transform: translateY(50%);
    /* top: 55%;
    transform: translateY(-50%); */
}

.p-carousel .batch-match-indicator {
    padding: 1rem;
    position: absolute;
}

#rightbars .p-carousel .batch-match-indicator {
    left: 10%;
}
#leftdotdbars .p-carousel .batch-match-indicator {
    left: 30%;
}
#leftdotdbars .p-carousel .batch-match-indicator,
#rightbars .p-carousel .batch-match-indicator {
    display: none;
}
/* batch match buttons*/

.request-interview-btn {
    min-width: 110px;
    max-width: 141px;
    width: 100%;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
    border: 2px solid #39c21e !important;

    background: #39c21e;
    border-radius: 3.93833px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 0.813rem;

    color: #ffffff;
}

.request-interview-btn:hover {
    background: #39c21e !important;
    color: #ffffff !important;
    border: 2px solid #39c21e !important;
}

.request-interview-btn:focus {
    box-shadow: none;
}

.short-list-btn {
    min-width: 110px;
    max-width: 141px;
    width: 100%;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
    border: 2px solid #e4e30a !important;

    background: #e4e30a;
    border-radius: 3.93833px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 0.813rem;

    color: #000000;
}

.short-list-btn:hover {
    background: #e4e30a !important;
    color: #000000 !important;
    border: 2px solid #e4e30a !important;
}

.short-list-btn:focus {
    box-shadow: none;
}

.decline-btn {
    min-width: 110px;
    max-width: 141px;
    width: 100%;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
    border: 2px solid #e00000 !important;

    background: #e00000;
    border-radius: 3.93833px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 0.813rem;

    color: #fff;
}

.decline-btn:hover {
    background: #e00000 !important;
    color: #fff !important;
    border: 2px solid #e00000 !important;
}

.decline-btn:focus {
    box-shadow: none;
}

.share-btn {
    min-width: 110px;
    max-width: 141px;
    width: 100%;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
    border: 2px solid #55baf8 !important;

    background: #55baf8;
    border-radius: 3.93833px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 0.813rem;

    color: #fff;
}

.share-btn:hover {
    background: #55baf8 !important;
    color: #fff !important;
    border: 2px solid #55baf8 !important;
}
.share-btn:focus {
    box-shadow: none;
}

.batch-match-navigator img {
    max-width: 100%;
}

/* Scroller*/
.work-history {
    height: 270px;
}
.dahboard-popular {
    height: 480px !important;
}

.work-history {
    overflow-y: scroll;
}
.work-history::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}
/* Track */
.work-history::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 5px;
}
/* Handle */
.work-history::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
    border-radius: 10px;
}
/* Handle on hover */
.work-history::-webkit-scrollbar-thumb:hover {
    height: 10px;
}

/** batch match request dialogue*/

.batch-match-request-interview {
    width: 40vw;
    height: 100vh;
    max-height: 100%;
    background: #ffffff;
    box-shadow: -8px 0px 9px 7px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 0 !important;
}

.batch-match-request-interview .p-dialog-content::-webkit-scrollbar {
    display: none !important;
}

.batch-match-request-interview-main-heading {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 1.7rem;
    letter-spacing: 0.03em;

    color: #000000;
}

.batch-match-request-interview-disclaimer-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #9bafbc;
}

.batch-match-request-interview-input-text {
    background: rgba(85, 186, 248, 0.06);
    border: 1px solid #55baf8;
    border-radius: 8px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #55baf8;
}
.batch-match-request-interview-input-text::placeholder {
    color: #55baf8;
}

/**Interview Tracker*/
.filter-button {
    box-sizing: border-box;

    padding: 6px 16px;

    background: #55baf8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
}

.filter-button-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    min-width: 70px;
    font-size: 1.15rem;
    color: #ffffff;
}

.interview-tracker-search-container {
    box-sizing: border-box;
    background: rgba(85, 186, 248, 0.06);
    border: 1px solid #55baf8;
    border-radius: 8px;
}

.search-input::placeholder {
    color: #55baf8;
}

.interview-tracker-in-active-phase {
    background: #ffffff;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;
    color: #8d8d8d;
    box-shadow: none;
    padding: 0.5rem 2rem;
    border: 2px solid #ffffff;
}

.interview-tracker-in-active-phase-2 {
    color: #8d8d8d;

    background: #ffffff;
    border-radius: 4px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;
    box-shadow: none;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
}

.interview-tracker-datatable {
    /* border-collapse: separate !important; */
    border-spacing: 10px !important;
}
.interview-tracker-datatable .p-datatable-thead > tr > th {
    text-align: center !important;
    padding: 16px 16px;
    border: 1px solid rgba(141, 141, 141, 0.24);
    border-width: 0 0 1px 0;

    transition: box-shadow 0.2s;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;

    color: #000000;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 97%, rgba(141, 141, 141, 0.24) 100%);
}

.interview-tracker-datatable .p-datatable-tbody > tr td:first-child {
    border-top-left-radius: 12% !important;
    border-bottom-left-radius: 12% !important;
}

.interview-tracker-datatable .p-datatable-tbody > tr td:last-child {
    border-top-right-radius: 12% !important;
    border-bottom-right-radius: 12% !important;
}

.interview-tracker-datatable .p-datatable-thead > tr {
    background: transparent;
}

.interview-tracker-datatable .p-datatable-tbody > tr {
    background: #fff;
    border-radius: 10px;
    border-top: 10px solid #f7fafc;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;

    color: #000000;

    transition: box-shadow 0.2s;
    margin-bottom: 10px !important;
}

.interview-tracker-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: none;
    border-width: 0 0 1px 0;
    padding: 16px 16px;
}

.interview-stage-text {
    font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-decoration-line: underline;

    color: #55baf8;
}

.interview-stage-text-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;

    color: #55baf8;
}

.potential-offer-text {
    font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: 400;
    font-size: 14.7387px;

    text-decoration-line: underline;

    color: #50c13a;
}

.expected-salary-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 0.921rem;
    color: #000000;
}
/* .interview-tracker-paginator */

.interview-tracker-datatable .p-paginator-bottom {
    border-width: 0 0 0 0;
    border-radius: 0;
    background: #f7fafc !important;
}

.interview-tracker-paginator {
    margin-top: 40px;
    padding-right: 0;
}

.interview-tracker-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #55baf8;
    border-radius: 3px;

    height: 40px !important;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;
    color: #ffffff;
    border: 0.5px solid #55baf8;
}

.interview-tracker-paginator .p-paginator-pages .p-paginator-page {
    height: 40px !important;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;
    color: #ffffff;
    background-color: transparent;
    border: 0.5px solid #eeeeee;
    border-radius: 3px;
    color: #000000;
    transition: none;
}

.interview-tracker-paginator .p-paginator-prev {
    height: 40px;
    border: 0.5px solid #eeeeee;
    border-radius: 3px;
    transition: none;
    background-color: transparent;
}

.interview-tracker-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover {
    background: #f7fafc;
    border-color: #eeeeee;
    color: #343a40;
}

.interview-tracker-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover {
    background: #f7fafc;
    border-color: #eeeeee;
    color: #343a40;
    position: absolute;
    top: calc(238%+50px);
    right: 79px;
}

.interview-tracker-paginator .p-paginator-next {
    height: 40px;
    border: 0.5px solid #eeeeee;
    border-radius: 3px;
    transition: none;
    background-color: transparent;
}

.interview-tracker-paginator .p-paginator-current {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem;
    line-height: 19px;

    color: #000000;
    padding: 0 0.5rem;
}

/** Pending candidates*/
.pending-candidates-search-filter-dropdown {
    box-sizing: border-box;
    background: #55baf8;
    border-radius: 5px;

    display: flex;
    align-items: center;
}

.pending-candidates-search-filter-dropdown .p-dropdown-trigger-icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(192deg) brightness(102%) contrast(104%);
}

.interview-tracker-paginator .p-paginator-pages .p-link:focus {
    box-shadow: none;
}

.pending-candidates-search-filter-dropdown .p-dropdown-label {
    color: #fff;
}

.interview-tracker-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #f7fafc;
    border-color: transparent;
    color: #343a40;
    border: 0.5px solid #eeeeee;
}

.pending-candidates-search-filter-dropdown .p-dropdown-label.p-placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;

    letter-spacing: 0.02em;
    color: #ffffff;
}

.pending-candidates-text-1 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;

    color: #181c20;
}

.pending-candidates-text-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;

    color: #181c20;
}

.pending-candidates-card-1 {
    background: #ffffff;
    border-radius: 10px;
}

.setting-card {
    background: #ffffff;
    border: 3px solid #55baf8;
    border-radius: 10px;
}

.setting-label {
    font-family: 'Helvetica Now Text';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #171725;
}

.profile-menu .p-menuitem-link .p-menuitem-text {
    font-family: 'Helvetica Now Text';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #171725;
}

.pending-candidates-heading-card {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    color: #000000;
}

.pending-candidate-image img {
    max-width: 105px;
    max-height: 95px;
    height: 100%;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 100px;
}

.single-candidate-container {
    background: #f7fafc;
    border-radius: 10px;
    /* padding: 16px 16px 0 16px; */
}

.profile-drop-down img {
    width: 20px;
    height: 14px;
    transform: rotate(180deg);
    cursor: pointer;
}

.pending-candidate-name {
    font-family: 'Helvetica Now Text';
    font-style: normal;
    font-weight: 700;
    font-size: 1.15rem;

    color: #171725;
}

.office_location_action_text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

.p-paginator-first {
    display: none;
}

.p-paginator-last {
    display: none;
}

/* Scroller*/

.pending-card-items-container {
    overflow-y: scroll;
}
.pending-card-items-container::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}
/* Track */
.pending-card-items-container::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 5px;
}
/* Handle */
.pending-card-items-container::-webkit-scrollbar-thumb {
    background: #55baf8;
    border-radius: 10px;
}
/* Handle on hover */
.pending-card-items-container::-webkit-scrollbar-thumb:hover {
    height: 10px;
}

/** recruiters*/

.recruiters-profile-image img {
    max-width: 89px;
    max-height: 90px;
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

/* Scroller*/

.recent-job-data-container {
    overflow-y: scroll;
}
.recent-job-data-container::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}
/* Track */
.recent-job-data-container::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 5px;
}
/* Handle */
.recent-job-data-container::-webkit-scrollbar-thumb {
    background: #55baf8;
    border-radius: 10px;
}
/* Handle on hover */
.recent-job-data-container::-webkit-scrollbar-thumb:hover {
    height: 10px;
}

/* .pi-chevron-down:before {
    content: url('../../assets/images/drop_down_icon.svg') !important;
} */

/** job posting dialogues*/

.job-posting-select-all-text {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 600;
    font-size: 0.75rem;

    color: #55baf8;
}

.add-more-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;

    letter-spacing: 0.02em;
    text-decoration-line: underline;

    color: #55baf8;
}

.save-job-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.15rem;
    letter-spacing: 0.02em;
    color: #000000;
}

.payment-plain-btn {
    background: #000000;
    border-radius: 5px;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 0.813rem;

    text-align: center;

    color: #ffffff;
}

.payment-plain-amount {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;

    letter-spacing: 0.03em;

    color: #4a4a4a;
}

.payment-plain-yearly-amount {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 8.45565px;

    color: #55baf8;
}

.payment-plain-text-2 li {
    list-style: inside !important;
    margin: 4px 0;
}
.payment-plain-text-2 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #000000;
    text-align: left;
}

.pe-none .become-btn,
.pe-none {
    pointer-events: none;
}
.applied-promo-code {
    color: #00c624;
    font-weight: 800;
    font-size: 18px;
}
.become-btn {
    background: #55baf8;
    border-radius: 5px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    padding: 6px 24px;

    color: #ffffff;
}

.plain-container {
    background: #f7fafc;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #dadada;
    min-height: 250px;
    min-width: 195px !important;
}
.plain-container.loggedin {
    pointer-events: none;
}
.plain-container-selected {
    border-radius: 5px;
    padding: 10px;
    min-height: 250px;
    background: rgba(85, 186, 248, 0.04);
    border: 1.5px solid #55baf8 !important;
    cursor: pointer;
}
.cards-plans:hover {
    border: 1.5px solid #55baf8 !important;
    background: rgba(85, 186, 248, 0.04);
}

.congra-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1.75rem;

    letter-spacing: -0.003em;

    color: #181c20;
}

.payment-success-info {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-size: 1.43rem;

    color: #111522;
}

.dropdown-job-post {
    background: #ffff !important;
    border: none;
}
.dropdown-job-post:focus {
    box-shadow: none;
    border-radius: none !important;
}

.dropdown-job-post .p-dropdown-label.p-placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #111522 !important;
}

.dropdown-job-post .p-dropdown-trigger-icon {
    filter: brightness(0) saturate(100%) invert(70%) sepia(14%) saturate(5827%) hue-rotate(176deg) brightness(103%) contrast(94%);
}

.interview-process-marker {
    background: #55a4e6;
    border: 1px solid #55a4e6;
    width: 45px;
    height: 42px;
    max-width: 45px;
    min-width: 45px;
    border-radius: 21px;
    color: #fff;
}

.customized-timeline .p-timeline-event-content {
    padding-top: 5px !important;
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.customized-timeline {
    font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;

    letter-spacing: -0.003em;

    color: rgba(49, 71, 95, 0.8);
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    border: 1px dashed #31475f;
    border-spacing: 3px;
}

.customized-timeline-item {
    margin-right: 8px; /* Adjust right margin to reduce space between items */
}

.customized-timeline-separator {
    margin-left: 4px; /* Adjust left margin to reduce space between separators */
}

.customized-datatable-row {
    border-radius: 15px !important; /* Adjust border radius value to your desired level */
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0;
}

.social-media-dropdown:focus-visible {
    border: none !important;
    box-shadow: none !important;
}

.circular-slider-value-1 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;

    color: #8d8d8d;
}

.circular-slider-dropdown {
    background: #ffff !important;
    border: none;
    border-radius: 46px;
}
.circular-slider-dropdown :focus {
    box-shadow: none;
    border-radius: none !important;
}

.circular-slider-dropdown .p-dropdown-label.p-placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #7b7b7b !important;
    padding-right: 0;
}

.circular-slider-dropdown .p-dropdown-label {
    color: #7b7b7b !important;
}

.circular-slider-dropdown .p-dropdown-trigger-icon {
    filter: brightness(0) saturate(100%) invert(70%) sepia(14%) saturate(5827%) hue-rotate(176deg) brightness(103%) contrast(94%);
}

.circular-slider-panel {
    background: transparent;
    box-shadow: none;
}

.circular-slider-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #55a4e6 !important;

    padding-left: 31px;
    border-radius: 46px;
    margin-top: 10px;

    background: rgba(85, 186, 248, 0.04) !important;
    border: 1.5px solid #55baf8;
    border-radius: 46px;
}

.circular-slider-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {
    color: #7b7b7b !important;
    background: #fff !important;
    padding-left: 31px;
    border-radius: 46px;
    margin-top: 10px;
}

.my-custom-item {
    background: rgba(85, 186, 248, 0.04);
    border: 1.5px solid #55baf8;
    border-radius: 46px;
}

.custom-div-slider .p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    color: white;
    background-color: #854dff;
    width: 43px;
    height: 43px;
}

.custom-div-slider .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: white;
    background-color: #854dff;
    width: 43px;
    height: 43px;
}

.custom-div-slider .pi-chevron-right:before {
    content: url('../../assets/images/blue_forward_icon.svg');
}

.custom-div-slider .pi-chevron-left:before {
    content: url('../../assets/images/blue_back_icon.svg');
}

/* candidate step components */
.steps {
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    color: #8d8d8d;
}

/* candidate onboarding screens */

.candiate-onboarding-screen-software-container {
    background: #ffffff;
    border-radius: 6px;
}
.candiate-onboarding-screen-software-text {
    background: #f7fafc;
    border-radius: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #7b7b7b;
}

.candiate-onboarding-screen-software-container {
    overflow-y: scroll;
}
.candiate-onboarding-screen-software-container::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}
/* Track */
.candiate-onboarding-screen-software-container::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 5px;
}
/* Handle */
.candiate-onboarding-screen-software-container::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
    border-radius: 10px;
}
/* Handle on hover */
.candiate-onboarding-screen-software-container::-webkit-scrollbar-thumb:hover {
    height: 10px;
}

.candiate-onboarding-screen-recent-work-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1.15rem;

    color: #7b7b7b;
}
.employment-calender {
    background: #ffffff;
    border-radius: 5px;
    border-color: #fff;
}

.employment-calender input {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #9f9f9f;
    border-color: #fff;
}

.employment-calender input:enabled:focus {
    border-color: #fff;
    box-shadow: none;
}
.employment-calender input:enabled:hover {
    border-color: #fff;
    box-shadow: none;
}

.employment-calender button {
    background: #fff;
    border-color: #fff;
}

.pi-calendar {
    content: url('../../assets/images/calender.svg') !important;
}

.selected-cross-icon {
    filter: brightness(0) saturate(100%) invert(71%) sepia(16%) saturate(6802%) hue-rotate(177deg) brightness(105%) contrast(95%);
}

.employment-calender button:enabled:hover {
    background: #fff;
    border-color: #fff;
}
.employment-calender button:enabled:focus {
    box-shadow: none;
}

.work-history-2 {
    height: 310px;
    background: #ffffff;
}

.work-history-2 {
    overflow-y: scroll;
}
.work-history-2::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}
/* Track */
.work-history-2::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 5px;
}
/* Handle */
.work-history-2::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
    border-radius: 10px;
}
/* Handle on hover */
.work-history-2::-webkit-scrollbar-thumb:hover {
    height: 10px;
}

.side-bar-icon {
    display: none !important;
}

.p-sidebar .p-sidebar-header {
    position: absolute;
    right: 10px;
}

.save-job img {
    filter: brightness(0) saturate(100%) invert(90%) sepia(88%) saturate(3543%) hue-rotate(350deg) brightness(107%) contrast(91%);
}

.interview-stage-checkbox .p-checkbox-box {
    width: 18px !important;
    height: 18px !important;
}

.profile-office-images img {
    max-width: 256px;
    width: 100%;
    max-height: 237px;
    height: 100%;
}

.edit-blue-image img {
    filter: brightness(0) saturate(100%) invert(68%) sepia(9%) saturate(3412%) hue-rotate(172deg) brightness(100%) contrast(95%);
}

.discount-div {
    background: #f56c6c;
    color: #fff;
    font-size: 10px;
    padding: 3px 5px;
    border-radius: 5px;
}

.interview-tracker-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
    display: none;
}

.job-actions-dropdown {
    border: none;
}

.job-actions-dropdown .p-dropdown-trigger .pi-chevron-down {
    content: url('../../assets/images/more_info_icon.svg') !important;
}

.circular-slider-custom div div div code {
    display: none !important;
}

.circular-slider-custom div div div {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 26px !important;

    color: #55baf8;
}

.progress-custom-container {
    position: relative;
}
.min-value {
    position: absolute;
    z-index: 100;
    left: 70px;
    top: 55px;
}

.auto-complete-add-button-input input {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #7b7b7b;
    border: none;
    width: 100%;
}

.auto-complete-add-button-input input:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #fff;
}

/** MEDIA QUERY FOR SMALL DEVICES*/

@media (max-width: 1024px) {
    .employee-love-container-txt {
        font-size: 1rem;
    }
    .batch-match-operations {
        right: -30px;
    }

    .dental-tools-edit {
        width: 45vw;
        height: 40vw;
    }
}

@media (max-width: 991px) {
    .batch-match-container .p-carousel-items-content .p-carousel-items-container .p-carousel-item {
        overflow-x: scroll;
    }
    .p-paginator {
        padding: 0.5rem 0.5rem;
    }

    .p-datatable .p-paginator .p-dropdown {
        display: none;
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 2.5rem;
        height: 2.5rem;
    }
    .primary-heading {
        font-size: 2.6rem;
    }
    .secondary-heading {
        font-size: 1.7rem;
    }
    .dialog-box {
        width: 80vw;
        height: 70vw;
    }

    .dialog-box-3 {
        width: 60vw;
        height: 50vw;
    }
    .back-image {
        display: none;
    }

    .p-carousel .batch-match-indicator {
        transform: translate(-52%, -50%);
    }

    .dental-tools-edit {
        width: 60vw;
        height: 50vw;
    }
    .side-bar-icon {
        display: block !important;
    }

    .logo-container {
        display: none;
    }
}

@media (max-width: 570px) {
    .employer-onboarding-content-container {
        width: 100%;
    }
    .primary-heading {
        font-size: 24px;
        line-height: 35px;
        margin-top: -20px !important;
    }
    .dialog-box {
        width: 90vw;
        height: 100%;
    }

    .dialog-box-3 {
        width: 80vw;
        height: 70vw;
    }
    .dialog-container {
        display: flex;
        flex-direction: column;
    }

    .back-icon {
        margin-left: 50px;
    }
    .employer-onboarding-logo-container {
        display: none !important;
    }
    .back-image {
        display: flex;
    }
    .wrapper {
        padding: 0;
    }
    .layout-topbar {
        padding: 0 1rem;
    }

    .p-carousel .batch-match-indicator {
        transform: translate(-52%, -50%);
    }

    .batch-match-operations {
        position: relative;
        display: flex;
        flex-direction: column;
        right: 0;
        left: 25%;
        transform: none;
    }

    .dental-tools-edit {
        width: 80vw;
        height: 70vw;
    }
    .review-bg-image {
        opacity: 0.5;
    }
    .side-bar-icon {
        display: block !important;
    }
}

@media (max-width: 768px) {
    .layout-topbar div.grid.w-full.align-items-center.justify-content-around {
        margin-left: 14px;
    }
    .layout-topbar div.grid.w-full.align-items-center.justify-content-around span.p-image.p-component img {
        max-width: 85px;
    }
}

@media (max-width: 425px) {
    .candidate-search-container {
        border-left: none;
    }
}
.chatsystem .employee-message-candidate-message-sender-container {
    background: transparent;
    padding: 5px !important;
}
.chatsystem img {
    max-width: 140px;
    border-radius: 4px;
    margin-right: 3px;
}
.edit-blue-image img {
    margin-left: 30px;
}
.specialty {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.employer-profile span.edit-blue-image img,
.candidate-profile span.edit-blue-image img,
.candidate-profile span.edit-white-icon img {
    position: absolute;
    right: 10px;
    top: 8px !important;
}
img[alt='cross icon'] {
    cursor: pointer;
}
div.addedSoftware {
    border: 2px solid #55baf8;
    border-radius: 6px;
}
input::placeholder {
    color: #888 !important;
}
.candidate-profile span.edit-white-icon img {
    top: 10px !important;
}
.employee-message-candidate-message-sender-container.type-text {
    background: #b5e1fc;
    border-radius: 7px;
    padding: 6px 8px !important;
    max-width: 350px;
}

.interviewsend {
    overflow: hidden;
}
div#hidepencil .edit-blue-image img,
div#hidepencil span.p-image.p-component.pt-2.mr-3.cursor-pointer img {
    display: none;
}

.candidate-onboarding .p-password .pi {
    color: #6c757d;
    position: absolute;
    top: 20px;
    left: 78%;
}
.p-password .pi {
    color: #6c757d;
    position: absolute;
    top: 20px;
    left: 91%;
}
#userSubscribed .plain-container.loggedin {
    pointer-events: none;
}
.become-btn.mt-3.cursor-pointer.subscribed {
    background-color: #00c624;
}
.historty-li li {
    list-style: inside !important;
}
.confirmation-dialog .p-dialog-footer {
    text-align: center;
    border-radius: 0px;
}
.p-message-wrapper {
    padding: 10px;
    text-align: left;
}
.cancel-subsc {
    font-size: 11px;
    color: red;
    text-decoration: underline;
}
.p-confirm-popup-content {
    padding: 10px !important;
    font-weight: bold;
}
.rc-slider-rail,
.rc-slider-step,
.rc-slider-track,
.rc-slider-tracks {
    height: 20px !important;
}
.slider-span {
    display: block;
    min-width: 380px;
}
div.rc-slider-handle {
    width: 25px;
    height: 25px;
}
.minmax-slider p {
    font-weight: 800 !important;
}
.minmax-slider {
    margin: auto;
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    max-width: 200px;
}
span.rc-slider-mark-text {
    color: #232323;
    top: 14px;
    font-size: 16px;
    font-weight: 900;
}
.job-card span.p-image.p-component.m-1.w-full.full-img,
.job-card span.p-image.p-component.full-img {
    display: inline-block;
    min-height: 310px;
}
.job-card .flex.mt-3.gap-2.justify-content-start.align-items-center {
    min-height: 33px;
    flex-wrap: wrap;
    max-height: 10px;
    overflow: hidden;
}
.job-card span.p-image.p-component.w-full.full-img img {
    max-height: 300px;
    min-height: 300px;
    border-radius: 10px;
}
.circular-slider-panel ul.p-dropdown-items {
    background-color: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.editcustom {
    position: absolute;
    right: 7px;
}
.requestsent {
    padding: 4px 47px 4px 47px !important;
    position: absolute;
    bottom: 35px;
    left: -37px;
    border: 0px;
    border-radius: 0px;
    transform: rotate(37deg);
    cursor: unset;
    pointer-events: none;
}
.requestsent1 {
    padding: 4px 53px 4px 45px !important;
    position: absolute;
    bottom: 23px;
    left: -61px;
    border: 0px;
    border-radius: 0px;
    transform: rotate(35deg);
    cursor: unset;
    pointer-events: none;
}
.privacy p,
.terms p {
    margin: 15px;
    line-height: 20px;
}
.privacy h5,
.terms h5 {
    font-size: 16px;
    font-weight: 600;
}
.terms ul li {
    list-style: disc;
}
.terms a {
    color: blue;
}
.oneline {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 350px;
}
.headedit {
    filter: brightness(0) saturate(100%) invert(68%) sepia(9%) saturate(3412%) hue-rotate(172deg) brightness(100%) contrast(95%);
}

@media (max-width: 572px) {
    .review-pic-title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .review-pic-title > div:first-child {
        text-align: center;
    }

    .review-pic-title > div + div {
        width: 100%;
        text-align: center;
    }
}
#notallowed-add {
    cursor: not-allowed !important;
}
span.p-image.p-component.job-card-profile-image img {
    border: 1px solid #d1d1d1;
}
#CandidateProfileImage img {
    filter: blur(6px);
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 303px; /* Adjust the max-width as per your requirement */
}
.officeAddress {
    font-size: 20px;
    font-weight: 600;
    color: #1967d2;
    text-decoration: underline;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
    padding-left: 5px;
}
