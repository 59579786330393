/*
 FONT FAMILY IMPORT 
*/

@import url('https://fonts.googleapis.com/css2?family=Inter&family=Manrope&family=Open+Sans&family=Raleway&family=Red+Hat+Text&display=swap');

@font-face {
    font-family: 'Helvetica Now Text';
    src: url('../fonts/helvetica_now_text/HelveticaNowText-Regular.otf');
}
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Light.otf');
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/Proxima_Nova/ProximaNova-Regular.otf');
}

/* VARIABLES FOR APP */

:root {
    /* PRIMARY COLORS */

    --primary: #55baf8;
    --primary-border: #6367f1;
    --primary-text: #fff;
    --primary-background: #f7fafc;
    --secondary-background: #f7fafc;

    --text-color: #6367f1;
    --primary-color: black;
    --primary-color-text: black;

    /* PRIMARY COLORS HOVER EFFECT */
    --primary-hover: #4337c9;
    --primary-hover-border: #4337c9;
    --primary-hover-text: white;

    /* SECONDARY COLORS */
    --secondary: #fff;
    --secondary-border: #64748b;
    --secondary-text: #55baf8;
    --text-secondary-color: #64748b;

    /* SECONDARY COLORS HOVER EFFECT */
    --secondary-hover: green;
    --secondary-hover-border: green;
    --secondary-hover-text: white;

    /* BORDER RADIUS */
    --border-radius: 6px;

    /* FONT FAMILY*/
    --font-family: 'Raleway', sans-serif;
    --font-style: normal;
    --font-family-heading: 'Open Sans', sans-serif;
}

/* RESET CSS */

* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-family: var(--font-family);
    box-sizing: border-box;
}

html {
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 1.5rem 0 1rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: var(--surface-900);
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}
