.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 7rem 2rem 2rem 3rem;
    transition: margin-left 0.2s;
}

@media (min-width: 992px) {
    .layout-wrapper.layout-static .layout-main-container {
        margin-left: 250px;
    }

    .layout-wrapper.layout-static.layout-static-inactive .layout-main-container {
        margin-left: 0;
        padding-left: 2rem;
    }

    .layout-wrapper.layout-static.layout-static-inactive .layout-sidebar {
        transform: translateX(-100%);
        left: 0;
    }

    .layout-wrapper .layout-mask {
        display: none;
    }
}

@media (max-width: 991px) {
    .layout-wrapper .layout-main-container {
        margin-left: 0;
        padding-left: 2rem;
        padding-right: 1.5rem;
    }

    .layout-wrapper.layout-mobile-active .layout-mask {
        display: block;
        animation: fadein 0.2s;
    }

    .layout-wrapper .layout-mask {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 998;
        width: 100%;
        height: 100%;
        background-color: var(--maskbg);
    }
}

@media (max-width: 570px) {
    .layout-main-container {
        padding: 0;
    }

    .layout-wrapper .layout-main-container {
        margin-left: 0;
        padding-top: 7rem;
    }
}
