.layout-topbar-landing {
    position: relative;
    height: 5rem;
    z-index: 997;
    width: 100%;
    padding: 0 2rem;
    background-color: #854dff;
    transition: left 0.2s;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 5px rgb(0 0 0 / 2%), 0 0 2px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 8%);
}

.layout-topbar-landing .layout-topbar-landing-logo {
    display: flex;
    align-items: center;
    color: var(--surface-900);
    font-size: 1.5rem;
    font-weight: 500;
    width: 300px;
    border-radius: 12px;
}

.layout-topbar-landing .layout-topbar-landing-logo img {
    height: 2.5rem;
    margin-right: 0.5rem;
}

.layout-topbar-landing .layout-topbar-landing-menu .layout-topbar-landing-button {
    margin-left: 1rem;
}

.layout-topbar-landing .layout-topbar-landing-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: transparent;
    border: none;
}

.layout-topbar-landing .layout-topbar-landing-button:hover {
    color: var(--text-color) !important;
    background-color: var(--surface-hover) !important;
}

.layout-topbar-landing .layout-topbar-landing-button i {
    font-size: 1.5rem;
}

.layout-right-wrap {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
}

.layout-topbar-landing .layout-topbar-landing-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.layout-topbar-landing .layout-topbar-landing-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: none;
}

.layout-topbar-landing .layout-topbar-landing-button span {
    font-size: 1rem;
    display: none;
}

.layout-topbar-landing .layout-topbar-landing-menu.layout-topbar-landing-menu-mobile-active {
    display: block;
}

.layout-topbar-landing .layout-topbar-landing-menu {
    margin-left: 0;
    position: absolute;
    flex-direction: column;
    background-color: var(--surface-overlay);
    box-shadow: 0 3px 5px rgb(0 0 0 / 2%), 0 0 2px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 8%);
    border-radius: 12px;
    padding: 1rem;
    right: 2rem;
    top: 5rem;
    min-width: 15rem;
    display: none;
    animation: scalein 0.15s linear;
}

.layout-topbar-landing .layout-topbar-landing-menu .layout-topbar-landing-button {
    margin-left: 0;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-start;
    border-radius: 12px;
    padding: 1rem;
}

.layout-topbar-landing .layout-topbar-landing-menu .layout-topbar-landing-button i {
    font-size: 1rem;
    margin-right: 0.5rem;
}

.layout-topbar-landing .layout-topbar-landing-menu .layout-topbar-landing-button span {
    font-weight: medium;
    display: block;
}

.p-overlay-badge {
    position: relative;
}

.p-overlay-badge .p-badge {
    background-color: #eee !important;
    font-size: 13px !important;
}

@media (max-width: 991px) {
    .layout-topbar-landing {
        justify-content: space-between;
        padding: 0 0.5rem;
    }

    .layout-topbar-landing .layout-topbar-logo {
        width: auto;
    }
}
