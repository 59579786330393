.layout-sidebar {
    position: fixed;

    width: 18.75rem;

    background: #ffffff;
    box-shadow: 3px 4px 19px rgba(85, 186, 248, 0.06);
    height: 100vh;
    z-index: 999;
    overflow-y: auto;
    top: 5rem;
    margin-bottom: 10px;
    padding: 0.5rem 1.5rem;
}

.layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 77%;

    overflow-y: scroll;

    gap: 0.625rem;

    width: 17.188rem;
}

/* Scroller*/

.layout-menu {
    overflow-y: scroll;
}
.layout-menu::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    padding-left: 10px;
}
/* Track */
.layout-menu::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 5px;
}
/* Handle */
.layout-menu::-webkit-scrollbar-thumb {
    background: #55baf8;
    border-radius: 10px;
}
/* Handle on hover */
.layout-menu::-webkit-scrollbar-thumb:hover {
    height: 10px;
}

.layout-menu li a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 1.5rem 1rem 1.25rem;
    gap: 10px;
    cursor: pointer;
    position: relative;
    background: #ffffff;
    border-radius: 8px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;

    color: #8d8d8d;
}

.layout-menu li:hover a {
    background: #e7f5fe;
    border-radius: 8px;
    color: #55baf8;
    width: 16.188rem;
}

.layout-menu li a img {
    filter: invert(58%) sepia(80%) saturate(0%) hue-rotate(307deg) brightness(82%) contrast(97%);
}

.layout-menu li:hover a img {
    filter: none;
}

.layout-menu li a .layout-menuitem-icon {
    margin-right: 0.5rem;
    background: transparent;
}

.layout-menu li a.active-route {
    font-weight: 500;
    background: #e7f5fe;
    border-radius: 8px;
    color: #55baf8;
    width: 16.188rem;
}

.layout-menu li a.active-route img {
    filter: none;
}

.layout-menu li:last-child a {
    width: 16.188rem;
    position: absolute;
    bottom: 0;
    margin-bottom: 100px;
    background: rgba(224, 0, 0, 0.06) !important;
    color: #e00000;
}

.layout-menu li:last-child a img {
    filter: none;
}

.pi-fw {
    width: 1.28571429em;
    text-align: center;
}

.p-ripple {
    overflow: hidden;
    position: relative;
}

.layout-menu ul a.active-route {
    font-weight: 700;
    color: var(--primary-color);
}

.layout-menuitem-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;

    color: #8d8d8d;
}

.dashboard-user-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;

    color: #7e7d82;
}

.p-sidebar .p-sidebar-header {
    display: none;
}

@media (min-width: 992px) {
    .layout-wrapper.layout-static.layout-static-inactive .layout-sidebar {
        transform: translateX(-100%);
        left: 0;
    }
}

@media (max-width: 991px) {
    .layout-wrapper.layout-mobile-active .layout-sidebar {
        transform: translateX(0);
    }

    .layout-wrapper .layout-sidebar {
        transform: translateX(-100%);
        left: 0;
        top: 0;
        height: 100vh;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
